// @ts-nocheck
/* eslint-enable */
import AppActions from 'app/shared/flux/actions/AppActions';

const GoogleMapsLoader = {};
GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
GoogleMapsLoader.WINDOW_CALLBACK_NAME = '__google_maps_api_provider_initializator__';
GoogleMapsLoader.URL = 'https://maps.googleapis.com/maps/api/js';
GoogleMapsLoader.requested = false;
GoogleMapsLoader.LOADING = 'async';

// Check current version of (Quarterly) Google Maps library here:
// https://developers.google.com/maps/documentation/javascript/reference
// https://developers.google.com/maps/documentation/javascript/releases
GoogleMapsLoader.VERSION = '3.56';

const gmapLoader = {
    appendMapsScript() {
        // This ultimately sets the window.google.maps object.
        let script = document.createElement('script');
        script.setAttribute('rel', 'preconnect');
        script.type = 'text/javascript';
        script.src = gmapLoader.createApiUrl();
        document.body.appendChild(script);
    },

    createApiUrl() {
        let url = GoogleMapsLoader.URL;
        url += '?callback=' + GoogleMapsLoader.WINDOW_CALLBACK_NAME;

        if (GoogleMapsLoader.KEY) {
            url += '&key=' + GoogleMapsLoader.KEY;
        }

        if (GoogleMapsLoader.LOADING) {
            url += '&loading=' + GoogleMapsLoader.LOADING;
        }

        if (GoogleMapsLoader.LIBRARIES.length > 0) {
            url += '&libraries=' + GoogleMapsLoader.LIBRARIES.join(',');
        }

        if (GoogleMapsLoader.CLIENT) {
            url += '&client=' + GoogleMapsLoader.CLIENT;
        }

        if (GoogleMapsLoader.VERSION) {
            url += '&v=' + GoogleMapsLoader.VERSION;
        }

        return url;
    },

    loadApi(ready) {
        window[GoogleMapsLoader.WINDOW_CALLBACK_NAME] = ready;
        gmapLoader.appendMapsScript();
    }
};

const initGoogleMap = ({ key = '' } = {}) => {
    return new Promise((resolve) => {
        /**
         * On slower devices, we can get into a state where
         * `window.google` exists but `window.google.maps` doesn't.
         * Potentially due to Google Analytics.
         * This ends up preventing the maps API from ever loading.
         * This is a more robust check for that scenario.
         */
        setTimeout(() => {
            const googleAttached = window.google || {};

            // Appends google maps object to client browser window
            if (__CLIENT__ && !googleAttached.maps && navigator.onLine && !GoogleMapsLoader.requested) {
                GoogleMapsLoader.requested = true;
                GoogleMapsLoader.KEY = key;
                gmapLoader.loadApi(() => {
                    window.store.dispatch(AppActions.gmapApiSuccess());
                    resolve({ apiLoaded: true });
                });
            } else if (__CLIENT__ && googleAttached.maps) {
                resolve({ apiLoaded: true });
            }
        }, 0);
    });
};

export default initGoogleMap;
