import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import assign from 'lodash/assign';
import cx from 'classnames';

import MAP_CONSTANTS from 'app/shared/constants/MapConstants';
import AppActions from 'app/shared/flux/actions/AppActions';
import VisuallyHidden from 'app/shared/modules/VisuallyHidden';
import gmapInit from 'app/client/utils/map/gmapInit';
import gmapUtils from 'app/client/utils/map/gmapUtils';
import './style.scss';

import { ReactNode } from 'react';
import { Dispatch } from 'redux';
import { ReduxProps } from 'app/types';

export interface GoogleMapsProps {
    attachWindow?: boolean;
    children?: ReactNode;
    disablePanZoom?: boolean;
    googleMapsKey?: string;
    onBoundsChanged: (map: any) => void;
    onMapResize: (map: any) => void;
    onMapClick: () => void;
    onMapDblClick: () => void;
    onMapDrag: () => void;
    onMapFirstIdle: (map: any) => void;
    onMapZoom: (map: any) => void;
    onMapIdle: () => void;
    onTilesLoaded: () => void;
    lat?: number | null;
    lon?: number | null;
    setMap: (map: any) => void;
    zoom?: number | null;
    hidden?: boolean;
    ariaLabel?: string | null;
    accessibleInstructions?: string | null;
}

export interface GoogleMapComponentProps extends GoogleMapsProps, ReduxProps { }

interface GoogleMapState {
    mapLoaded: boolean;
}

class GoogleMap extends React.Component<GoogleMapComponentProps, GoogleMapState> {
    static defaultProps: Partial<GoogleMapComponentProps> = {
        attachWindow: true,
        children: [],
        disablePanZoom: false,
        googleMapsKey: '',
        onMapResize: () => { },
        onBoundsChanged: () => { },
        onMapClick: () => { },
        onMapDblClick: () => { },
        onMapDrag: () => { },
        onMapFirstIdle: () => { },
        onMapZoom: () => { },
        onTilesLoaded: () => { },
        lat: null,
        lon: null,
        setMap: () => { },
        zoom: null,
        hidden: false,
        ariaLabel: null,
        accessibleInstructions: null
    };

    private googleMapRef: React.RefObject<HTMLDivElement>;
    private initialized: boolean;
    private map: any;
    private mapsLibrary: any;

    constructor(props: GoogleMapComponentProps) {
        super(props);
        this.state = {
            mapLoaded: false
        };
        this.googleMapRef = React.createRef();
        this.initialized = false;
        this.map = null; // Stores Google Map instance / object
        this.mapsLibrary = null; // Stores Google Maps Library.
    }

    componentDidMount() {
        const { googleMapsKey } = this.props;

        gmapInit({ key: googleMapsKey })
            .then(() => this.initMap())
            .catch((err) => {
                console.warn('Error loading map:', err);
            });
    }

    componentDidUpdate(prevProps: GoogleMapComponentProps) {
        const { props } = this;

        if (!this.map) {
            this.initMap();
            return;
        }

        if (!props.lat || !props.lon) {
            return;
        }

        if (props.lat !== prevProps.lat || props.lon !== prevProps.lon) {
            this.updateMapLocation();
        }

        if (prevProps.zoom !== props.zoom) {
            this.map.setZoom(props.zoom);
            props.setMap(this.map); // ensure we pass the correct
        }

        if (prevProps.isMobile !== props.isMobile) {
            this.map.setOptions({ zoomControl: !props.isMobile });
        }
    }

    componentWillUnmount() {
        if (!this.props.attachWindow) {
            return;
        }

        if (this.map && this.mapsLibrary) {
            window.removeEventListener('resize', this.handleMapResize);
            this.mapsLibrary.event.clearInstanceListeners(window.map);
            this.mapsLibrary.event.clearInstanceListeners(this.map);
        }

        this.map = null;
        this.mapsLibrary = null;
        window.map = null;
        this.props.dispatch(AppActions.setAppStoreBool('gmapLoaded', false));

        delete this.map;
    }

    attachListeners = () => {
        const { map } = this;
        const {
            onBoundsChanged,
            onMapClick,
            onMapDblClick,
            onMapIdle,
            onMapDrag,
            onMapFirstIdle,
            onTilesLoaded,
            onMapZoom
        } = this.props;

        if (this.props.attachWindow) {
            window.addEventListener('resize', this.handleMapResize);
            window.google.maps.event.addListenerOnce(map, 'idle', () => onMapFirstIdle(map));
            window.google.maps.event.addListenerOnce(map, 'tilesloaded', () => onTilesLoaded());

            window.google.maps.event.addListener(map, 'bounds_changed', () => onBoundsChanged(map));
            window.google.maps.event.addListener(map, 'click', onMapClick);
            window.google.maps.event.addListener(map, 'dblclick', onMapDblClick);
            window.google.maps.event.addListener(map, 'dragend', onMapDrag);
            window.google.maps.event.addListener(map, 'zoom_changed', () => onMapZoom(map));

            if (onMapIdle) {
                window.google.maps.event.addListener(map, 'idle', onMapIdle);
            }
        }
    };

    updateMapLocation = () => {
        const { lat, lon } = this.props;
        const currentMapData = gmapUtils.getMapData(this.map) as {
            lat: number
            lon: number
        };
        const MOVE_THRESHOLD = 0.0003;

        // TODO: Update "lon" or "lng"?

        if (lat && lon) {
            // Due to capping lat / lon at 4 decimals, there ends up being some slight imprecision when
            // zoomed in to neighborhoods and listings, causing the map to move after debounce as
            // coords get trimmed. This checks if we meet a certain threshold before panning the map.
            const latMoved = Math.abs(lat - currentMapData.lat) > MOVE_THRESHOLD;
            const lonMoved = Math.abs(lon - currentMapData.lon) > MOVE_THRESHOLD;

            if (latMoved || lonMoved) {
                const latLng = new window.google.maps.LatLng(lat, lon);
                this.map.panTo(latLng);
            }
        }
    };

    initMap = () => {
        const { disablePanZoom, isMobile } = this.props;

        // LatLng not always initialized in time on slow/throttled devices. Need to check for it explicitly until we fix slow initialization bug.
        const windowAvailable = window?.google?.maps?.LatLng;

        if (this.initialized || !windowAvailable) {
            return;
        }

        const { lat, lon, setMap, zoom } = this.props;
        this.initialized = true;

        const center = new window.google.maps.LatLng(lat, lon);
        const mapOptions = assign(
            {},
            {
                center,
                clickableIcons: false,
                fullscreenControl: false,
                gestureHandling: disablePanZoom ? 'none' : 'greedy',
                mapTypeControl: false,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                panControl: false,
                streetViewControl: false,
                mapId: MAP_CONSTANTS.ID,
                zoom: Number(zoom),
                zoomControl: !isMobile, // eslint-disable-line no-unneeded-ternary
                zoomControlOptions: {
                    style: window.google.maps.ZoomControlStyle.SMALL,
                    position: window.google.maps.ControlPosition.LEFT_BOTTOM
                }
            }
        );

        // Init the map!
        this.mapsLibrary = window.google.maps;
        const node = ReactDOM.findDOMNode(this.googleMapRef.current);
        this.map = new this.mapsLibrary.Map(node, mapOptions);
        this.attachListeners();

        if (this.props.attachWindow) {
            window.map = this.map;
        }

        this.initMapOverlays();
        setMap(this.map);
        this.setState({ mapLoaded: true });

        window.google.maps.event.trigger(this.map, 'resize');
    };

    initMapOverlays = () => {
        if (this.props.attachWindow) {
            window.bicycleOverlay = new window.google.maps.BicyclingLayer();
            window.transitOverlay = new window.google.maps.TransitLayer();
        }
    };

    handleMapResize = () => {
        const { onMapResize } = this.props;
        onMapResize(this.map);
    };

    render() {
        const { children, hidden, ariaLabel, accessibleInstructions } = this.props;
        const { mapLoaded } = this.state;

        if (!mapLoaded) {
            return <div className="GoogleMap" ref={this.googleMapRef} id="GoogleMapContainer" />;
        }

        return (
            <div
                className={cx('GoogleMap', { 'GoogleMap-hidden': hidden })}
                aria-label={ariaLabel || undefined}
                aria-describedby={accessibleInstructions ? 'accessible-instructions' : undefined}
                id="GoogleMapContainer"
            >
                {accessibleInstructions && (
                    <VisuallyHidden id="accessible-instructions">{accessibleInstructions}</VisuallyHidden>
                )}
                <div ref={this.googleMapRef}>
                    {React.Children.map(children, (child) => {
                        if (!child) {
                            return null;
                        }
                        return React.cloneElement(child as React.ReactElement<any>, {
                            map: this.map,
                            key: (child as React.ReactElement<any>).key
                        });
                    })}
                </div>
            </div>
        );
    }
}

// TODO: Need to type out Redux properly. For now, this works:
interface AppState {
    googleMaps: {
        key: string;
    };
    device: {
        screenWidth: string;
    };
}

interface RootState {
    app: AppState;
}

const mapStateToProps = (state: RootState) => {
    return {
        googleMapsKey: state.app.googleMaps.key,
        isMobile: state.app.device.screenWidth === 'sm'
    };
};

export default connect(mapStateToProps)(GoogleMap);
